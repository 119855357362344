import React from "react";
import { graphql } from 'gatsby';

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Blog from '../template/pages/Blog';

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          <Blog {...props} />
      </Layout>
    </>
}
export default Page;

